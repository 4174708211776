/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import styled from "styled-components";

import Logo from "../logo.png";
import Cosmos from "../assets/cosmos.svg";
import HC from "../assets/hc.svg";

// @ts-ignore
import { API, setAccessibleApplications } from "@roambee/client-utility";
// @ts-ignore
import { Small, Title, InfoboxCard } from "@roambee/client-styleguide";

function Home() {
	const navigate = useNavigate();
	const [applications, setApplications] = useState([]);

	// styles
	const Label = styled(Small)`
		margin-top: 16px;
	`;
	const TitleLabel = styled(Title)`
		width: 232px;
		margin-top: 56px;
		font-size: 24px;
	`;
	const Card = styled(InfoboxCard)`
		margin-top: 24px;
	`;

	useEffect(() => {
		API("POST", "/user/me", {}).then(
			(result) => {
				setApplications(result.applications || []);

				// set apps so that it can be used for route protection (implementation is inside root/src/roambee-client-root.js)
				setAccessibleApplications(result.applications || []);
			}
			// (error) => {
			// 	// console.error("Error => ", error);
			// }
		);
	}, []);

	function getApplicationRoute(app_code) {
		switch (app_code) {
			case "hc20":
				return "/hc20";
			case "cosmos":
				return "/cosmos";
			case "reveal":
				return "/reveal";
			case "resolve":
				return "/resolve";
			case "integrationhub":
				return "/integrationhub/dashboard";
			case "container":
				return "/container";
			default:
				return "/";
		}
	}

	function getApplicationImage(app_code) {
		switch (app_code) {
			case "hc20":
				return HC;
			case "cosmos":
				return Cosmos;
			case "firmware":
				return Cosmos;
			default:
				return HC;
		}
	}

	function getFirmwareRoute() {
		const firmwareUrl = process.env.CLIENT_FIRMWARE_URL;
		window.open(firmwareUrl + "/management", "_blank");
	}

	function ApplicationListLayout() {
		if (applications.length) {
			const ApplicationLayout = applications.map((application) => {
				return (
					<div role="presentation" className="rb-list-item" key={application.app_code} onClick={() => navigate(getApplicationRoute(application.app_code))}>
						<div className="rb-list-item-img">
							<img src={getApplicationImage(application.app_code)} alt="App" />
							<div className="rb-item-header">{application.name}</div>
						</div>
						<div className="rb-list-item-info">{application.description}</div>
					</div>
				);
			});
			return (
				<div className="rb-list">
					{ApplicationLayout}
					<div role="presentation" className="rb-list-item" key="firmware" onClick={getFirmwareRoute}>
						<div className="rb-list-item-img">
							<img src={getApplicationImage("firmware")} alt="App" />
							<div className="rb-item-header">Firmware Management</div>
						</div>
						<div className="rb-list-item-info">Firmware Updates and Release Status by Device</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="applications-list-layout">
					<Card>No application is configured yet!</Card>
				</div>
			);
		}
	}

	return (
		<div>
			<section id="main-container">
				<img src={Logo} className="rb-logo" alt="logo" />
				<TitleLabel $bold>Welcome to the Honeycomb Platform</TitleLabel>
				<Label>Enter one application</Label>
				<ApplicationListLayout />
			</section>
		</div>
	);
}

export default Home;
